export const aboutTofinoData = [
  { heading: "About Tofino", 

    subHeading: "",

    paragraph1a: "Tofino, a beautiful village on the rugged west coast of Vancouver Island. Fill your days with exploration and adventure. Hiking trails abound, the miles of beaches delight visitors with their beauty. Visit giant cedar trees in Pacific Rim Park and on Meares Island on incredible rain forest trails.",
    paragraph1b: "Enjoy an afternoon on the ocean whale watching, sighting orcas, humpbacks sea otters and sea lions. Visit the inside waters to watch bears and wolves feeding at low tide.", 

    image1: "GAL-17.jpg",

    paragraph2: 
    "Tofino is a fishing village with charters leaving daily to catch large chinook salmon and halibut which are plentiful during summer months. Kayak rentals and tours are available for those wishing to take a closer look at shorelines and beaches.", 

    paragraph3: "Tofino is the surfing capital of Canada and just outside your door at Gold Coast Retreat is the famous Chesterman Beach. A two or three minute walk to the beach and you are having the time of your life. Wetsuits and surfboards can be rented or bought nearby in several shops. The surf shops also provide daily lessons for everyone from the uninitiated to skilled surfers.", 
    paragraph4: "Rent a bike and ride the beaches or take the bike path into town or all the way to Long Beach and beyond through the National Pacific Rim Park. Discover tide pools or build sand castles. Relax and let the sound of the waves sooth your soul. The village of Tofino has over 30 restaurants to prepare your fine dining experience. Cut loose and enjoy Tofino from one of our comfortable cottages.", 
  }
]