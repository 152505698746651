import React from 'react'
import styled from "styled-components"
import Img from "gatsby-image"
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"
import PageHeader from "../elements/pageHeader"

const InfoTofino = ({props, image}) => {
  
  return (
    <>
    {props.map((item, index) => (
      <>
    <PageHeader header={item.heading} subHeader={item.subHeading}/>
    <InfoContainer>
          <Top>
            <Paragraph1 key={index}>
              {item.paragraph1a} <br />
              {item.paragraph1b}
            </Paragraph1>
            <Image fluid={image.file.childImageSharp.fluid} alt="sunset image"/>
          </Top>          
          <Middle>
            <Paragraph2>
              {item.paragraph2}
            </Paragraph2>
          </Middle>
          <Bottom>
            <Paragraph3>
              {item.paragraph3}
            </Paragraph3>
            <Paragraph4>
              {item.paragraph4}
            </Paragraph4>
          </Bottom>
          </InfoContainer>
        </>
        ))}
    </>
  )
}

export default InfoTofino

const InfoContainer = styled.div`
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #000;
  background-image: url(${backgroundImageSand});
  display: flex;
  flex-direction: column;
  padding-top: 0;
  
  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
    padding-top: 0;
  }
`

const Top = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }

  br {
    margin-bottom: 2rem;
  }
`

const Image = styled(Img)`
  border-radius: 100%;
  width: 400px;
  height: 100%;
  align-self: center;
  margin: auto;

  @media screen and (max-width: 868px){
    width: 50%;
    height: 100%;
  }
`

const Middle = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`

const Bottom = styled.div`
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
`

const Paragraph1 = styled.p`
  margin-top: 2rem;
`
const Paragraph2 = styled.p``
const Paragraph3 = styled.p``
const Paragraph4 = styled.p``


