import React from "react"
import {aboutTofinoData} from "../data/aboutTofinoData"
import Layout from "../components/layout"
import SEO from "../components/seo"
import InfoTofino from "../components/infoTofino"
import CabinsFullWidth from "../components/cabinsFullWidth"
import { useStaticQuery, graphql } from "gatsby"

const AboutTofino = () => {
  const image = useStaticQuery(graphql`
    query {
      file(name: {eq: "GAL-17"}) {
        childImageSharp {
          fluid (maxWidth:500) {
             ...GatsbyImageSharpFluid_noBase64
           }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About Gold Coast" />
      <InfoTofino props={aboutTofinoData} image={image}/>
      <CabinsFullWidth />
    </Layout>
  )
}

export default AboutTofino
